import React from 'react';
import { useCurrentUser, useTranslate } from '@hooks';
import { Table, StatApprovalBadge as ApprovalBadge } from '@dotone/react-ui-core';
import { formatDateTime, formatCurrency } from '../formatters';

const formatOrderNumber = (cell, t) => cell || t('common:na');

const formatApproval = (cell) => <ApprovalBadge block value={cell} className="text-rg" />;

const getColumns = ({ t, currency }) => [
  {
    dataField: 'transactionId',
    text: 'ID',
    hidden: true,
  },
  {
    dataField: 'capturedTime',
    text: t('capturedTime'),
    formatter: formatDateTime(),
  },
  {
    dataField: 'affiliateId',
    text: t('affiliateId'),
  },
  {
    dataField: 'offer.name',
    text: t('offerName'),
  },
  {
    dataField: 'stepLabel',
    text: t('conversionLabel'),
  },
  {
    dataField: 'order.orderNumber',
    text: t('orderNumber'),
    formatter: (cell) => formatOrderNumber(cell, t),
  },
  {
    dataField: 'truePay',
    text: t('payout'),
    formatter: formatCurrency(currency),
  },
  {
    dataField: 'approval',
    text: t('status'),
    formatter: formatApproval,
  },
];

const ConversionTable = (props) => {
  const { currency } = useCurrentUser();
  const { t } = useTranslate('dashboard', 'recentConversions');
  const columns = getColumns({ t, currency }).map((col) => ({
    ...col,
    style: { minWidth: 'auto' },
    headerClasses: 'text-wrap',
  }));

  return (
    <Table
      remote
      hover
      classes="stylish-table mb-0"
      keyField="transactionId"
      columns={columns}
      fullWidth
      responsive
      {...props}
    />
  );
};

export default ConversionTable;
